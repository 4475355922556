import { Access } from './access.model';
import { Base } from './base.model';
import { Company } from './company.model';

export class User extends Base<User> {

  companyId?: number;

  name: string;

  email: string;
  password?: string;

  phoneNumber: string;

  confirmPassword?: string;

  isAdmin: boolean;
  hasAccessEagleEye: boolean;
  hasAccessGateKeeper: boolean;
  mobileAccess: boolean;

  updatedAt?: number;
  createdAt?: number;
  createdBy?: number;

  accesses: Access[];
  subscriptions: any[];
  company: Company;
  projectAdmin: number[];

  constructor(model?: Partial<User>) {
    super(model);

    if (this.isAdmin === undefined) {
      this.isAdmin = false;
    }
    if (this.hasAccessEagleEye === undefined) {
      this.hasAccessEagleEye = true;
    }
    if (this.hasAccessGateKeeper === undefined) {
      this.hasAccessGateKeeper = false;
    }
    if (this.accesses === undefined) {
      this.accesses = [];
    }
  }
}

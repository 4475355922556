
export const environment = {
  production: true,
  name: 'production',
  API_URL: 'https://lyntonsurveys.appspot.com/',
  BUGSNAG_API_KEY: 'f93fc7fc411b26788039d9e96d0a194c',
  unsplash: {
    access: '609491ba41ac62bd226579b9a0deaee51e557456a0738ed61b328b3e1ed19e60',
    private: '638f0b5f17b5250acaf581c285cec38d8e20b4a032b8c41ffc46b12c10a1acd1',
  },
  logs: {
    sendRequest: false,
    receiveRequest: false,
    dashboard: false,
  },
  firebaseConfig: {
    apiKey: 'AIzaSyCqdZee5ekZk_2yCJVWd106x4tbjMsq2_c',
    authDomain: 'lyn-eagle-eye.firebaseapp.com',
    projectId: 'lyn-eagle-eye',
    storageBucket: 'lyn-eagle-eye.appspot.com',
    messagingSenderId: '506850708036',
    appId: '1:506850708036:web:b48828f46315715dfeca11',
    measurementId: 'G-TDHPPJQY5B'
  },
  reporting: {
    URL: 'https://lyn-reporting.web.app',
  }
};

import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from './app.service';
import { LoadingService } from './loading/loading.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private loading: LoadingService, public router: Router, public app: AppService) {
    this.loading.start();
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CSVService } from './csv.service';
import { DeviceDetectorService } from './device-detector';
import { EmptyResponseBodyInterceptor } from './empty-response-body-error-interceptor';
import { ErrorService } from './error.service';
import { TableUtil } from './table.util';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    CSVService,
    EmptyResponseBodyInterceptor,
    TableUtil,
    ErrorService,
    DeviceDetectorService
  ]
})

export class UtilsModule { }

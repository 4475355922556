import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { getAnalytics, provideAnalytics } from '@angular/fire/analytics';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { APP_VERSION, DEBUG_MODE, ScreenTrackingService } from '@angular/fire/compat/analytics';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import BugsnagPerformance from '@bugsnag/browser-performance';
import Bugsnag from '@bugsnag/js';
import { environment } from 'src/environments/environment';
import pck from '../../package.json';
import { AppComponent } from './app.component';
import { AppRoutes } from './app.routes';
import { AppService } from './app.service';
import { AuthInterceptor } from './auth/auth.interceptor';
import { AuthModule } from './auth/auth.module';
import { HeaderComponent } from './header/header.component';
import { BUGSNAG_CONFIG, errorHandlerFactory } from './helpers/bugsnag.helper';
import { LoadingModule } from './loading/loading.module';
import { EmptyResponseBodyInterceptor } from './utils/empty-response-body-error-interceptor';
import { UtilsModule } from './utils/utils.module';

// https://docs.bugsnag.com/platforms/javascript/angular/#installation
Bugsnag.start(BUGSNAG_CONFIG);
BugsnagPerformance.start({ apiKey: environment.BUGSNAG_API_KEY });

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
  ],
  imports: [
    RouterModule.forRoot(AppRoutes),
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LoadingModule,
    AuthModule,
    UtilsModule,
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideAnalytics(() => getAnalytics()),
  ],
  providers: [
    AppService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EmptyResponseBodyInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useFactory: errorHandlerFactory,
    },
    ScreenTrackingService,
    { provide: APP_VERSION, useValue: pck.version },
    { provide: DEBUG_MODE, useValue: false },
    { provide: LOCALE_ID, useValue: 'en-AU' }
  ],
  // exports: [RouterModule],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
  constructor() {
    console.log(`Eagle-Eye v.${pck.version}`);
  }
}

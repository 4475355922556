import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { DeviceDetectorService } from '../utils/device-detector';

@Injectable({
    providedIn: 'root'
})
export class MobileGuard  {

    constructor(
        private router: Router,
        private deviceDetector: DeviceDetectorService
    ) { }

    canActivate(ext: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.deviceDetector.isDesktop()) {
            return true;

        } else {
            this.router.navigateByUrl('/');
            return false;
        }
    }
}

export enum CHART_TYPE {
  MDG = 'mdg',
  PG = 'pg',
  ONE_D = '1d',
  THREE_D = '3d',
  DEVICE = 'singleDevice', // Single Device
  DEVICE_THREE_D = 'device3d', // Single Device
}

export enum CHART_AXIS {
  LEFT = 'y-axis-l',
  RIGHT = 'y-axis-r',
  X = 'x',
}


export function mapChartTye(type: CHART_TYPE): number | undefined {
  switch (type) {
    case CHART_TYPE.MDG: return 4;
    case CHART_TYPE.PG: return 3;
    case CHART_TYPE.ONE_D: return 5;
    case CHART_TYPE.THREE_D: return 6;
    default: return undefined;
  }
}

import { Injectable } from "@angular/core";
@Injectable()
export class TableUtil {

    sortBy;
    sortAscending = false;

    constructor() { }

    sort(table: any[], sortBy: string) {
        let filteredTable = [] as any[];

        this.sortAscending = this.sortBy === sortBy ? !this.sortAscending : this.sortAscending;
        this.sortBy = sortBy;

        const reverse = this.sortAscending ? 1 : -1;

        filteredTable = [...table.sort((a, b) => {
            const aAttr = this.processAttribute(a[sortBy]);
            const bAttr = this.processAttribute(b[sortBy]);

            // Null is considered as smaller than anyhting
            if (aAttr === null && bAttr === null) { return 0; }
            if (aAttr === null) { return -1 * reverse; }
            if (bAttr === null) { return 1 * reverse; }

            return (aAttr > bAttr) ? 1 * reverse : ((bAttr > aAttr) ? -1 * reverse : 0);
        })];
        return filteredTable;
    }

    // We need this method as IsNaN doesn't work properly with booleans
    private isNumeric(val) {
        return !Array.isArray(val) && (val - parseFloat(val) + 1) >= 0;
    }

    private processAttribute(attribute, ) {
        if (attribute === null || attribute === undefined) { return null; }

        if (typeof attribute === 'boolean') {
            return attribute;
        } else if (this.isNumeric(attribute)) {
            return parseFloat(attribute);
        } else {
            return attribute.toLowerCase();
        }
    }

    filter(table: any[], filterBy: string, exceptions: string[]) {
        let filteredTable = [] as any[];

        if (!filterBy) { filterBy = ''; }
        const filters = filterBy.toLowerCase().trim().split(' ');

        // When the search field is empty, the table has one empty string
        if (filters.length === 1 && filters[0] !== '') {
            filteredTable = table.filter(p => {

                // For all the attributes of the object
                for (const key in p) {

                    // Continue if the value is not null or if the attribute is not part of the excluded attributes
                    if (p[key] != null && !exceptions.includes(key)) {

                        // Check all the filter to see if it matches at least one
                        for (const filter of filters) {

                            // If the value is not a number
                            if (isNaN(+p[key]) && String(p[key]).toLowerCase().includes(filter)) {
                                return true;

                                // If the value and the filter are numbers
                            } else if (!isNaN(+p[key]) && !isNaN(+filter) && +p[key] === +filter) {
                                return true;

                            }
                        }
                    }
                }

                return false;
            });

        } else {
            filteredTable = table;
        }

        return filteredTable;
    }
}

<div class="div-header" [class.opened]="opened">

  <div class="div-nav">
    <div class="div-image">
      <img src="../../../assets/img/logo-landscape.png" alt="Eagle-Eye Logo" (click)="routeTo('/')">
    </div>
    <button class="btn-menu" (click)="opened = !opened">
      <div *ngIf="!opened"><i class="fas fa-bars fa-fw"></i></div>
      <div *ngIf="opened"><i class="fas fa-times fa-fw"></i></div>
    </button>
  </div>

  <div class="div-menu" *ngIf="auth.user" (click)="opened = false">
    <button (click)="routeTo('/')" title="Home"><i class="fas fa-home fa-fw"></i><span>Home</span></button>
    <button (click)="routeTo('/admin/users')" *ngIf="auth.user.isAdmin && deviceDetector.isDesktop()" title="Admin"><i class="fas fa-cog fa-fw"></i><span>Admin</span></button>
    <button (click)="app.readOnly = true" *ngIf="auth.isProjectAdmin() && deviceDetector.isDesktop() && !app.readOnly" title="Read Only"><i class="fas fa-lock-open-alt"></i></button>
    <button (click)="app.readOnly = false" *ngIf="auth.isProjectAdmin() && deviceDetector.isDesktop() && app.readOnly" title="Read Only"><i class="fas fa-lock-alt"></i></button>
    <button (click)="routeTo('/profile')" title="Profile"><i class="fas fa-user-circle fa-fw"></i><span>Profile</span></button>
    <button (click)="auth.logout()" title="Logout"><i class="fas fa-sign-out-alt fa-fw"></i><span>Logout</span></button>
  </div>
</div>
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class LoadingService {

    isLoading = false as boolean;

    constructor(private router: Router) {
        router.events.subscribe((val) => {
            // console.log('Router => ', val);
            // this.start();
        });
    }

    start() {
        this.isLoading = true;
    }

    complete() {
        this.isLoading = false;
    }
}

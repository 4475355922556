import { Injectable } from '@angular/core';

export const TOKEN_NAME = 'Lynton_Token' as string;

@Injectable()
export class TokenService {
    public setToken(token): void {
        if (token) {
            localStorage.setItem(TOKEN_NAME, token);
        } else {
            localStorage.removeItem(TOKEN_NAME);
        }
    }

    public getToken(): string {
        const token = localStorage.getItem(TOKEN_NAME);
        return token;
    }
}

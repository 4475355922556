import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '../app.service';
import { DeviceDetectorService } from '../utils/device-detector';
import { AuthService } from './../auth/auth.service';
import { LoadingService } from './../loading/loading.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public opened = false;

  constructor(
    public app: AppService,
    public auth: AuthService,
    public deviceDetector: DeviceDetectorService,
    private router: Router,
    private loadingService: LoadingService,
  ) { }

  ngOnInit() {
  }

  routeTo(route) {
    // Starts loading only if the user changes module
    let temp = this.router.url.substring(1);
    const root = temp.substring(0, temp.indexOf('/') > 0 ? temp.indexOf('/') : undefined);

    temp = route.substring(1);
    const targetRoot = temp.substring(0, temp.indexOf('/') > 0 ? temp.indexOf('/') : undefined);

    if (root !== targetRoot) {
      this.loadingService.start();
    }

    this.router.navigateByUrl(route);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Analytics, setUserId, setUserProperties } from '@angular/fire/analytics';
import { Router } from '@angular/router';
import Bugsnag from '@bugsnag/js';
import { lastValueFrom } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { Project } from '../models/project.model';
import { TokenService } from './token.service';

@Injectable()
export class AuthService {

  user: User;

  constructor(
    private http: HttpClient,
    private token: TokenService,
    private router: Router,
    private analytics: Analytics,
  ) { }

  login(email: string, password: string): Promise<any> {
    const data = {
      email: email,
      password: password
    };

    return lastValueFrom(this.http.post('auth/login', data))
      .then((response: any) => {
        setUserId(this.analytics, String(response.data.id));
        this.token.setToken(response.token);
        this.router.navigate(['/']);
      })
      .catch(err => {
        throw (err.error.message);
      });
  }

  currentUser(): Promise<User> {
    return new Promise(async (resolve, reject) => {
      try {
        const user = await lastValueFrom(this.http.get('auth/user')).then(item => new User(item));
        if (user && user.id) {
          this.setUser(user);
          resolve(user);
        }
      } catch (err) {
        this.logout();
        reject(false);
      }
    });
  }

  isProjectAdmin(projectId?: number) {
    if (projectId === undefined) {
      return this.user?.isAdmin || this.user.projectAdmin?.length > 0;
    } else {
      return this.user?.projectAdmin?.includes(projectId) || this.user?.isAdmin;
    }
  }

  private setUser(user: any) {
    if (user === null) {
      delete this.user;

    } else {
      // Google Analytics
      setUserId(this.analytics, String(user.id));
      setUserProperties(this.analytics, {
        companyName: user.companyName,
        companyId: user.companyId,
        isAdmin: user.isAdmin
      }, { global: true });

      // Bugsnag
      Bugsnag.setUser(String(user.id), user.email, user.name);

      this.user = user;
    }
  }

  forgotPassword(email: string): Promise<any> {
    const data = { email: email };
    return lastValueFrom(this.http.post('auth/forgotPassword', data));
  }

  resetPassword(token: string, password: string): Promise<any> {
    const data = {
      token: token,
      password: password
    };
    return lastValueFrom(this.http.post('auth/resetPassword', data));
  }

  logout(): void {
    this.setUser(null);
    this.token.setToken(null);
    this.router.navigate(['/login']);
  }

  getProjectByMDG(id: number): Promise<Project> {
    return lastValueFrom(this.http.get<Project>(`project/multi-device-graph?id=${id}`));
  }

  getProjectByPG(id: number): Promise<Project> {
    return lastValueFrom(this.http.get<Project>(`project/profile-graph?id=${id}`));
  }

  get isGlobalAdmin(): boolean {
    return this.user?.isAdmin;
  }
}

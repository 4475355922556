import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor() { }

  processServerError(err) {
    console.error(err);
    if (err.status === 0) {
      return 'Impossible to connect to the server. Please try again in a few minutes.';
    } else if (err.error) {
      return err.error.message;
    } else if (err.message) {
      return err.message;
    } else {
      return 'An error happened. If it persists, please contact the administrator.';
    }
  }
}

import { BrowserConfig } from "@bugsnag/js";
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular/dist/esm2015';
import { environment } from "src/environments/environment";
import { getVersion } from "./app.helper";

// create a factory which will return the Bugsnag error handler
export function errorHandlerFactory() {
  return new BugsnagErrorHandler();
}

/**
 * https://docs.bugsnag.com/platforms/javascript/angular/configuration-options/
 */
export const BUGSNAG_CONFIG: BrowserConfig = {
  apiKey: environment.BUGSNAG_API_KEY,

  releaseStage: environment.name,
  appVersion: getVersion(),

  // Update to admin, portal, app or anything that makes sense for your application
  appType: 'app',
};
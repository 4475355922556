import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { CHART_TYPE } from '../charts/chart.enum';
import { Project } from './../models/project.model';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class GraphProjectAdminGuard  {

  constructor(
    private auth: AuthService,
    private router: Router
  ) { }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    try {
      const user = await this.auth.currentUser();
      if (user.isAdmin) {
        return true;
      }

      const chartType: CHART_TYPE = route.data.chartType;
      const id = +route.paramMap.get('chartId')
      if (!chartType) {
        return true;
      }

      let project: Project;
      switch (chartType) {
        case CHART_TYPE.MDG:
        case CHART_TYPE.ONE_D:
        case CHART_TYPE.THREE_D:
          project = await this.auth.getProjectByMDG(id);
          break;
        case CHART_TYPE.PG:
          project = await this.auth.getProjectByPG(id);
          break;
      }

      if (!project) {
        return false;
      }

      return this.auth.isProjectAdmin(project.id);

    } catch (err) {
      this.router.navigate(['/login']);
      return false;
    }
  }
}

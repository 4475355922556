import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class AppService {
  fullscreen = false as boolean;
  readOnly = true as boolean; // Original Value

  constructor() {
    // On dev, we want to be able to edit the data straight away
    this.readOnly = environment.name === 'development' ? false : true;
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { DeviceDetectorService } from '../utils/device-detector';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard  {

    constructor(
        private router: Router,
        private auth: AuthService,
        private device: DeviceDetectorService,

    ) { }

    canActivate(ext: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.auth.currentUser().then(user => {

            if (!user.isAdmin && !this.device.isDesktop() && !user.mobileAccess) {
                this.router.navigateByUrl('/upgrade');
                return false;
            }

            return true;

        }).catch((err) => {
            this.router.navigateByUrl('/login');
            return false;
        });
    }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class AdminGuard  {

    constructor(private router: Router, private auth: AuthService) { }

    canActivate(ext: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.auth.currentUser().then(e => {
            if (e.isAdmin) {
                return true;
            } else {
                this.router.navigateByUrl('/');
                return false;
            }
        }).catch((err) => {
            this.router.navigate(['/login']);
            return false;
        });
    }
}

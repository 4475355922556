import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AdminGuard } from './admin.guard';
import { AuthGuard } from './auth.guard';
import { AuthInterceptor } from './auth.interceptor';
import { AuthService } from './auth.service';
import { GraphProjectAdminGuard } from './graph-project-admin.guard';
import { MobileGuard } from './mobile.guard';
import { TokenService } from './token.service';

@NgModule({
    imports: [
        CommonModule,
    ],
    providers: [
        AuthService,
        TokenService,
        AuthInterceptor,
        AuthGuard,
        AdminGuard,
        MobileGuard,
        GraphProjectAdminGuard
    ],
    declarations: []
})
export class AuthModule { }

import { Injectable } from '@angular/core';
import { Papa } from 'ngx-papaparse';

@Injectable({
  providedIn: 'root'
})
export class CSVService {

  constructor(private papa: Papa) { }

  /** Download a table as CSV. */
  downloadDataAsCSV(name: string, data: any[], config?: any) {
    const csv = this.papa.unparse(data, config);
    this.downloadStringAsCSV(name, csv);
  }

  /** Download a string as a CSV. */
  downloadStringAsCSV(name: string, csv: string) {
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    this.download(name, blob);
  }

  /** Download a Blog on client's computer */
  download(name: string, data: Blob) {
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(data);
    a.download = name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}

import { Injectable } from '@angular/core';

@Injectable()
export class DeviceDetectorService {

    constructor() { }


    isDesktop(): boolean {
        if (/Mobi|Android/i.test(navigator.userAgent)) {
            return false;
        } else {
            return true;
        }
    }
}

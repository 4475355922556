import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { AuthService } from './auth.service';
import { TokenService } from './token.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private token: TokenService, private auth: AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.prepareRequestWithHeaders(req)).pipe(
      tap(() => { }, err => this.errorReceived(err))
    );
  }

  private prepareRequestWithHeaders(req: HttpRequest<any>): HttpRequest<any> {
    if (req.url.startsWith('http') || req.url.includes('./assets')) {
      return req;
    } else {
      const authHeader = this.token.getToken() ? 'Bearer ' + this.token.getToken() : '';
      return req.clone({
        headers: req.headers.set('Authorization', authHeader),
        url: (!req.url.includes('./assets') && !req.url.startsWith('http')) ? environment.API_URL + req.url : req.url
      });
    }
  }

  private errorReceived(err) {
    if (err instanceof HttpErrorResponse
      && (err.status === 401 || err.status === 403)
      && err.url.startsWith(environment.API_URL) // Do not log out if GCP bucket sends 403
    ) {
      this.auth.logout();
    }
  }
}